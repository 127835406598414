<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{$t('tcb_report.district_wise_inspection_report')}}</h4>
      </template>
      <template v-slot:searchBody>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
          <b-row class="mt-4">
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <ValidationProvider name="Memo No." vid="notice_id" rules="required|min_value:1">
                  <b-form-group
                      class="row"
                      label-for="notice_id"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{ $t('exportTrophyCircular.memo_no') }}  <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                      plain
                      v-model="search.notice_id"
                      :options="circularList"
                      id="notice_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                      {{ errors[0] }}
                      </div>
                  </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <ValidationProvider name="Office" vid="office_id" rules="required|min_value:1">
                  <b-form-group
                      class="row"
                      label-for="office_id"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                      {{ $t('globalTrans.district')}}  <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                      plain
                      v-model="search.office_id"
                      :options="officeList"
                      id="office_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                      {{ errors[0] }}
                      </div>
                  </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4" class="mt-3">
              <b-button type="submit" variant="success" class="mr-2 btn-sm">{{$t('globalTrans.search')}}</b-button>
            </b-col>
          </b-row>
          </b-form>
        </ValidationObserver>
      </template>
      <!-- search section end -->
    </card>
    <body-card style="overflow-x: clip;">
      <!-- table section start -->
    <div>
      <report-heading/>
      <div class="text-right">
        <b-button variant="primary" @click="pdfExport" class="mr-2">
                  {{  $t('globalTrans.print') }}
        </b-button>
      </div>
      <p class="mb-4 text-center"><b>{{ $t('globalTrans.district') }} {{ $t('globalTrans.office') }}</b>: {{ getDcOfficeName(this.search.office_id) }}</p>
      <b-overlay :show="loadData">
          <table class="table-sm" style="width: 100%;">
            <thead>
              <tr>
                <th>{{ $t('globalTrans.sl_no') }}</th>
                <th>{{ $t('globalTrans.application_id') }}</th>
                <th>{{ $t('dealer.applicant_name') }}</th>
                <th>{{ $t('tcb_report.genuine_grocer') }}</th>
                <th>{{ $t('tcb_report.upto_date_license') }}</th>
                <th>{{ $t('tcb_report.grocery_store') }}</th>
                <th>{{ $t('tcb_report.store_capacity') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(app, index) in appList" :key="index">
                  <td>
                    {{ $n(index+1) }}
                  </td>
                  <td>
                    {{ $n(app.application_id, {useGrouping: false}) }}
                  </td>
                  <td>
                    {{ currentLocale === 'bn' ? app.details?.applicant_name_bn :  app.details?.applicant_name  }}
                  </td>
                  <td>
                      <i v-if="app.inspection?.comments_id.includes(1)" class="ri-check-line yes-btn" style="font-weight: 700"></i>
                      <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                  </td>
                  <td>
                      <i v-if="app.inspection?.comments_id.includes(2)" class="ri-check-line yes-btn" style="font-weight: 700"></i>
                      <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                  </td>
                  <td>
                      <i v-if="app.inspection?.comments_id.includes(3)" class="ri-check-line yes-btn" style="font-weight: 700"></i>
                      <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                  </td>
                  <td>
                      <i v-if="app.inspection?.comments_id.includes(4)" class="ri-check-line yes-btn" style="font-weight: 700"></i>
                      <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                  </td>
              </tr>
            </tbody>
          </table>
      </b-overlay>
    </div>
      <!-- table section end -->
    </body-card>
  </div>
</template>
<script>
import ModalBaseMasterList from '@/mixins/list'
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { districtWiseInspectionReport, circularsApi } from '../../api/routes'
import ReportHeading from '../../components/ReportHeading.vue'
import { helpers } from '@/utils/helper-functions'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    ReportHeading
  },
  data () {
    return {
      search: {
        notice_id: 0,
        office_id: 0
      },
      appList: '',
      show: false,
      loadData: false,
      circularList: []
    }
  },
  created () {
    this.getCirculars()
  },
  watch: {
    currentLocale: function (newVal, oldVal) {
        if (newVal !== oldVal) {
            this.circularList.map(item => {
                const customItem = { text: this.$i18n.locale === 'bn' ? helpers.convertEnglishToBanglaNumber(item.text_bn) : item.text_en }
                return Object.assign(item, customItem)
            })
        }
    }
  },
  computed: {
    officeList () {
      const officeList = this.$store.state.CommonService.commonObj.officeList.filter(item => item.status === 1 && item.office_type_id === 23)
      return officeList
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    getDcOfficeName (id) {
      const data = this.officeList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
        return ''
      }
    },
    async getCirculars () {
      this.loadData = true
      this.loader = true
      let result = null
      result = await RestApi.getData(licenseRegistrationServiceBaseUrl, circularsApi)
      if (result.success) {
          this.circularList = result.data.map(item => {
                                const customItem = { text: this.$i18n.locale === 'bn' ? helpers.convertEnglishToBanglaNumber(item.text_bn) : item.text_en }
                                return Object.assign(item, customItem)
                            })
          if (result.data.length > 0) {
            this.search.notice_id = result.data[0].value
          }
          this.loadData = false
          this.show = true
          this.loader = false
      } else {
          this.loadData = false
          this.loader = false
      }
    },
    async searchData () {
       let result = null
       this.appList = ''
      this.loadData = true
      result = await RestApi.getData(licenseRegistrationServiceBaseUrl, districtWiseInspectionReport, this.search)
      this.loadData = false
      if (result.success) {
          this.appList = result.data
          this.show = true
      }
    },
    async pdfExport () {
        this.loadData = true
        this.search.request_type = 'pdf'
        this.search.local = this.$i18n.locale
        const result = await RestApi.getPdfData(licenseRegistrationServiceBaseUrl, districtWiseInspectionReport, this.search)
        var blob = new Blob([result], {
              type: 'application/pdf'
          })
          this.loadData = false
        var url = window.URL.createObjectURL(blob)
        window.open(url).print()
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    EngBangNum (n) {
      if (this.$i18n.locale === 'bn') {
        return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
      } else {
        return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
      }
    }
  }
}
</script>
<style>
@import '../../style.css';
</style>
